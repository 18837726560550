exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-explore-victoria-js": () => import("./../../../src/pages/explore-victoria.js" /* webpackChunkName: "component---src-pages-explore-victoria-js" */),
  "component---src-pages-getting-around-melbourne-js": () => import("./../../../src/pages/getting-around-melbourne.js" /* webpackChunkName: "component---src-pages-getting-around-melbourne-js" */),
  "component---src-pages-holding-page-js": () => import("./../../../src/pages/holding-page.js" /* webpackChunkName: "component---src-pages-holding-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-news-js": () => import("./../../../src/pages/latest-news.js" /* webpackChunkName: "component---src-pages-latest-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-promo-toolkit-js": () => import("./../../../src/pages/promo-toolkit.js" /* webpackChunkName: "component---src-pages-promo-toolkit-js" */),
  "component---src-pages-sponsorship-js": () => import("./../../../src/pages/sponsorship.js" /* webpackChunkName: "component---src-pages-sponsorship-js" */),
  "component---src-pages-supporters-js": () => import("./../../../src/pages/supporters.js" /* webpackChunkName: "component---src-pages-supporters-js" */),
  "component---src-pages-template-js": () => import("./../../../src/pages/template.js" /* webpackChunkName: "component---src-pages-template-js" */),
  "component---src-pages-venue-js": () => import("./../../../src/pages/venue.js" /* webpackChunkName: "component---src-pages-venue-js" */)
}

